import {
    BaseMediaConfig,
    Event,
    ExhibitionPresentationMediaData,
    EXPERTISE_AREA_FIELD_LABEL,
    ExtraField,
    Form,
    GeneralizedFormConfig,
    OralPresentationMediaData,
    PosterPresentationMediaData,
    PresentationFormData,
} from '@models';
import { shouldShowFieldOnSubmissionForm } from './event.fn';
import { FormControl, Validators } from '@angular/forms';
import { SUBMISSION_EMAIL_PATTERN } from '@constants';
import { set } from 'lodash';

export const doesFormHaveFields = (form: { config: GeneralizedFormConfig }): boolean => {
    if (!form || !form.config || !form.config.extraFields) {
        return false;
    }
    return form.config.extraFields.length > 0;
};

export const isFormHasExpertiseAreaField = (form: { config: GeneralizedFormConfig }): boolean => {
    if (!form || !form.config || !form.config.extraFields) {
        return false;
    }
    return form.config.extraFields.filter(form => form.label === EXPERTISE_AREA_FIELD_LABEL).length > 0;
};

export const isRecruitmentForm = (form: Form) => form.type === 'abstract_recruitment' || form.type === 'recruitment';

export const getFormVisibleFields = (config: GeneralizedFormConfig): ExtraField[] => {
    return config.extraFields.filter(field => field.isDisplayed);
};

export const hasFormVisibleQuestions = (form: { config: GeneralizedFormConfig }): boolean => {
    return getFormVisibleFields(form.config).length > 0;
};

export const isFormHasFileInput = (form: Form) =>
    getFormVisibleFields(form.config).filter(field => field.type === 'file').length > 0;

export const isEmptyString = (input: string) => !input || (input || '').trim().length === 0;

export const shouldUseVideoFileUpload = (item: BaseMediaConfig) => item.videoType === 'upload';

export const validateYoutubeLink = (link: string): { valid: boolean; errMsg: string } => {
    const msgForInvalid = 'This is not a valid YouTube link.';
    const msgForShorts = 'YouTube Shorts videos are not supported.';
    const msgForWhitespace = 'There cannot be whitespace in the URL.';
    const youtubeLinkPattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    const trimmedLink = link.trim();

    if (!trimmedLink) {
        return {
            valid: false,
            errMsg: msgForInvalid,
        };
    }

    if (trimmedLink.includes(' ')) {
        return {
            valid: false,
            errMsg: msgForWhitespace,
        };
    }

    // Separate check for YouTube Shorts
    if (/youtube\.com\/shorts\/.+/.test(trimmedLink)) {
        return {
            valid: false,
            errMsg: msgForShorts,
        };
    }

    const match = trimmedLink.match(youtubeLinkPattern);
    if (!match || !match[1]) {
        return {
            valid: false,
            errMsg: msgForInvalid,
        };
    }
    return {
        valid: true,
        errMsg: null,
    };
};

export const validatePresentationSubmissionForm = (
    formValues: PresentationFormData,
    event: Event,
    formFiles: Partial<{ posterPoster: FileList; oralSlides: FileList }>,
    fileUploadLimitMB: number = 10,
    isEditAndSafe: boolean = false,
    currentStage: number = null
) => {
    const fileUploadLimit = fileUploadLimitMB * 1024 * 1024;
    const {
        presentationFormConfig: { presentationFields, presenterFields, mediaFields },
    } = event;
    const formErrs = {
        hasPresenterErrs: [],
    };

    const {
        presentationFormConfig: {
            mediaFields: { oral, poster, exhibition },
        },
    } = event;

    if (!currentStage) {
        currentStage = event.eventConfig.currentAbstractStage;
    }

    const setFormError = (key: string, error = null) => {
        if (error) {
            set(formErrs, key, error);
        }
    };
    const setRequiredFieldError = key => {
        setFormError(key, {
            isRequired: true,
        });
    };
    const setPresenterHasError = index => formErrs.hasPresenterErrs.push(index);
    const setRequiredPresenterFieldError = (index, key) => {
        setRequiredFieldError(`presenter_${index}_${key}`);
        setPresenterHasError(index);
    };
    // -- presenters --
    formValues.presenterData.forEach((presenter, presenterIdx) => {
        const keys = ['firstName', 'lastName', 'email'];
        const emailValidator = new FormControl(presenter.email.trim(), [
            Validators.email,
            Validators.pattern(SUBMISSION_EMAIL_PATTERN),
        ]);
        keys.forEach(key => {
            if (isEmptyString(presenter[key])) {
                setRequiredPresenterFieldError(presenterIdx, key);
            }
        });

        if (emailValidator.errors) {
            setFormError(`presenter_${presenterIdx}_email`, {
                isValid: true,
                errMsg: 'Please enter a valid email.',
            });
            setPresenterHasError(presenterIdx);
        }
        if (
            shouldShowFieldOnSubmissionForm(presenterFields.level, currentStage) &&
            presenterFields.level.isRequired &&
            !presenter.level
        ) {
            setRequiredPresenterFieldError(presenterIdx, 'level');
        }
        if (
            shouldShowFieldOnSubmissionForm(presenterFields.major, currentStage) &&
            presenterFields.major.isRequired &&
            isEmptyString(presenter.major)
        ) {
            setRequiredPresenterFieldError(presenterIdx, 'major');
        }
        for (const [index, extraFieldConfig] of presenterFields.extraFields.entries()) {
            if (
                shouldShowFieldOnSubmissionForm(extraFieldConfig, currentStage) &&
                extraFieldConfig.isRequired &&
                ((extraFieldConfig.type !== 'number' && !presenter.extraValues[extraFieldConfig.hash]) ||
                    (extraFieldConfig.type === 'number' && !presenter.extraValues[extraFieldConfig.hash]) ||
                    (extraFieldConfig.type === 'checkbox' && presenter.extraValues[extraFieldConfig.hash].length === 0))
            ) {
                setRequiredFieldError(`presenter_${presenterIdx}_extra_${index}`);
                setPresenterHasError(presenterIdx);
            }
        }
    });

    // -- presentation --
    if (shouldShowFieldOnSubmissionForm(presentationFields.title, currentStage) && !formValues.presentationData.title) {
        setRequiredFieldError('title');
    }
    if (
        shouldShowFieldOnSubmissionForm(presentationFields.abstract, currentStage) &&
        !formValues.presentationData.abstract
    ) {
        setRequiredFieldError('abstract');
    }
    if (
        shouldShowFieldOnSubmissionForm(presentationFields.subjects, currentStage) &&
        presentationFields.subjects.isRequired &&
        formValues.presentationData.subjects.length === 0
    ) {
        setRequiredFieldError('subjects');
    }
    for (const [index, extraFieldConfig] of presentationFields.extraFields.entries()) {
        if (
            shouldShowFieldOnSubmissionForm(extraFieldConfig, currentStage) &&
            extraFieldConfig.isRequired &&
            ((extraFieldConfig.type !== 'number' && !formValues.presentationData.extraValues[extraFieldConfig.hash]) ||
                (extraFieldConfig.type === 'number' &&
                    !formValues.presentationData.extraValues[extraFieldConfig.hash]) ||
                (extraFieldConfig.type === 'checkbox' &&
                    formValues.presentationData.extraValues[extraFieldConfig.hash].length === 0))
        ) {
            setRequiredFieldError(`presentation_extra_${index}`);
        }
    }

    // -- media --
    if (!shouldShowFieldOnSubmissionForm(mediaFields, currentStage, true)) {
        return formErrs;
    }

    if (!formValues.presentationType) {
        setRequiredFieldError('presentationType');
    }
    if (formValues.presentationType === 'poster') {
        if (!formFiles.posterPoster) {
            setRequiredFieldError('posterPoster');
        } else if (!isEditAndSafe) {
            const posterFile: File = formFiles.posterPoster.item(0);
            if (posterFile.type !== 'application/pdf') {
                setFormError('posterPoster', {
                    isValid: true,
                    errMsg: 'File is not a valid PDF!',
                });
            }
            if (posterFile.size > fileUploadLimit) {
                setFormError('posterPoster', {
                    isValid: true,
                    errMsg: 'File is too large!',
                });
            }
        }
        const mediaData = formValues.presentationMediaData as PosterPresentationMediaData;
        if (
            mediaData.voiceoverVideoLink &&
            !validateYoutubeLink(mediaData.voiceoverVideoLink).valid &&
            !shouldUseVideoFileUpload(poster)
        ) {
            setFormError('posterVoiceoverVideoLink', {
                isValid: true,
                errMsg: validateYoutubeLink(mediaData.voiceoverVideoLink).errMsg,
            });
        }
    }
    if (formValues.presentationType === 'oral') {
        const mediaData = formValues.presentationMediaData as OralPresentationMediaData;
        const { valid, errMsg } = validateYoutubeLink(mediaData.presentationVideoLink);

        if (!mediaData.presentationVideoLink) {
            setRequiredFieldError('oralPresentationVideoLink');
        } else if (!valid && !shouldUseVideoFileUpload(oral)) {
            setFormError('oralPresentationVideoLink', {
                isValid: true,
                errMsg,
            });
        }
        if (formFiles.oralSlides) {
            const slidesFile: File = formFiles.oralSlides.item(0);
            if (slidesFile.type !== 'application/pdf') {
                setFormError('oralSlides', {
                    isValid: true,
                    errMsg: 'File is not a valid PDF!',
                });
            }
            if (slidesFile.size > fileUploadLimit) {
                setFormError('oralSlides', {
                    isValid: true,
                    errMsg: 'File is too large!',
                });
            }
        }
    }
    if (formValues.presentationType === 'exhibition') {
        const mediaData = formValues.presentationMediaData as ExhibitionPresentationMediaData;
        const { valid, errMsg } = validateYoutubeLink(mediaData.presentationVideoLink);
        if (!mediaData.presentationVideoLink) {
            setRequiredFieldError('exhibitionPresentationVideoLink');
        } else if (!valid && !shouldUseVideoFileUpload(exhibition)) {
            setFormError('exhibitionPresentationVideoLink', {
                isValid: true,
                errMsg,
            });
        }
        if (mediaData.voiceoverVideoLink && !valid && !shouldUseVideoFileUpload(exhibition)) {
            setFormError('exhibitionVoiceoverVideoLink', {
                isValid: true,
                errMsg,
            });
        }
    }
    return formErrs;
};
