import {
    DEFAULT_GENERAL_CONFIG,
    DEFAULT_MEDIA_FIELDS_CONFIG,
    DEFAULT_PRESENTATION_FIELDS_CONFIG,
    DEFAULT_PRESENTER_FIELDS_CONFIG,
    PresentationFormConfig,
    PresentationFormData,
} from '@models';
import { QuillModules } from 'ngx-quill/src/quill-editor.interfaces';

export const DEFAULT_PRESENTATION_FORM_DATA: PresentationFormData = {
    presenterData: [
        {
            firstName: '',
            lastName: '',
            email: '',
            level: '',
            major: '',
            extraValues: {},
        },
    ],
    presentationData: {
        title: '',
        abstract: '',
        subjects: [],
        extraValues: {},
    },
    presentationType: '',
    presentationMediaData: {},
} as PresentationFormData;

export const DEFAULT_PRESENTATION_SUBMISSION_CONFIG = {
    general: { ...DEFAULT_GENERAL_CONFIG },
    presenterFields: { ...DEFAULT_PRESENTER_FIELDS_CONFIG },
    presentationFields: { ...DEFAULT_PRESENTATION_FIELDS_CONFIG },
    mediaFields: { ...DEFAULT_MEDIA_FIELDS_CONFIG },
} as PresentationFormConfig;

export const QUILL_ABSTRACT_CONFIG: Partial<QuillModules> = {
    toolbar: [
        ['bold', 'italic'], // toggled formatting buttons

        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

        ['link'], // links

        [{ list: 'bullet' }, { list: 'ordered' }], // lists

        ['clean'], // remove formatting button
    ],
    clipboard: {
        matchers: [
            ['img', () => ({ ops: [] })]
        ]
    }
};

export const QUILL_TITLE_CONFIG: Partial<QuillModules> = {
    toolbar: [
        ['italic'], // toggled formatting buttons

        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

        ['clean'], // remove formatting button
    ],
};

export const QUILL_GENERAL_PRESENTATION_SUBMISSION_CONFIG: Partial<QuillModules> = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled formatting buttons

        [{ align: [] }], // text alignment

        [{ color: [] }, { background: [] }], // dropdown with default colors from theme

        [{ font: [] }], // font

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

        [{ header: [1, 2, 3, 4, 5, 6, false] }], // html headers

        [{ list: 'bullet' }, { list: 'ordered' }], // lists

        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

        ['blockquote'], // quote

        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

        ['link'], // links

        ['clean'], // remove formatting button
    ],
};
export const SUBMISSION_EMAIL_PATTERN = /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;
