export type ExtraField = CustomInputFormField | CustomMultiSelectFormField | CustomLikertScaleFormField;

export interface GeneralizedFormConfig {
    general: GeneralConfig;
    extraFields: ExtraField[];
}

export interface PresentationFormConfig {
    general: GeneralConfig;
    presenterFields: PresenterFieldsConfig;
    presentationFields: PresentationFieldsConfig;
    mediaFields: MediaFieldsConfig;
}

export interface GeneralConfig {
    instructions: string;
}

export interface PresenterFieldsConfig {
    presenterTitle: string;
    maxAddlPresenters: number;
    firstNameDescription: string;
    lastNameDescription: string;
    emailDescription: string;
    level: CustomMultiSelectFormField;
    major: CustomInputFormField;
    extraFields?: Array<CustomInputFormField | CustomMultiSelectFormField | CustomLikertScaleFormField>;
}

export interface BaseMediaConfig {
    videoType?: 'youtube' | 'upload';
    isDisplayed: boolean;
    label: string;
    isOptionalFieldDisplayed: boolean;
}

export interface PresentationFieldsConfig {
    title: CustomInputFormField;
    abstract: CustomInputFormField;
    subjects: CustomMultiSelectFormField;
    mentor: CustomInputFormField;
    extraFields?: Array<CustomInputFormField | CustomMultiSelectFormField | CustomLikertScaleFormField>;
}

export interface MediaFieldsConfig {
    poster: PosterMediaFieldsConfig;
    oral: OralMediaFieldsConfig;
    exhibition: ExhibitionMediaFieldsConfig;
    stage?: number;
}

export interface PosterMediaFieldsConfig extends BaseMediaConfig {
    posterUploadDescription: string;
    voiceoverVideoLinkDescription: string;
}

export interface OralMediaFieldsConfig extends BaseMediaConfig {
    presentationVideoLinkDescription: string;
    slidesUploadDescription: string;
}

export interface ExhibitionMediaFieldsConfig extends BaseMediaConfig {
    presentationVideoLinkDescription: string;
    voiceoverVideoLinkDescription: string;
}

export interface BaseExtraField {
    hash?: string; // unique id of the field
    isDisplayed: boolean; // show/hide the field
    isRequired: boolean; // is the field required on submit
    isPermanent: boolean; // ability to remove the field from the form
    isShownInSummary: boolean;
    canChangeLabel: boolean; // ability to change the label
    label: string; // field label
    description: string; // field description
    conditionalQuestionAnswerPairs?: {}; // all pairs necessary to enable a question
    sharing?: {
        canShare: boolean;
        description: string; // description to display for the sharing option
        tooltipText?: string;
    };
    stage?: number;
}

export type InputType = 'text' | 'number' | 'wysiwyg' | 'file' | 'date' | 'datetime';

export enum UploadFileExtensions {
    ALL = 'ALL',
    IMAGE = 'IMAGE',
    PDF = 'PDF',
    VIDEO = 'VIDEO',
    CSV_DOCUMENT = 'CSV_DOCUMENT',
    EXCEL_DOCUMENT = 'EXCEL_DOCUMENT', // .xls, .xlsx
    MICROSOFT_DOCUMENT = 'MICROSOFT_DOCUMENT', // anything that smells like a microsoft word document
    POWERPOINT = 'POWERPOINT',
}

export const FILE_EXTENSION_MAP = {
    [UploadFileExtensions.ALL]: '',
    [UploadFileExtensions.IMAGE]: 'image/* , image/png, image/jpeg , image/jpg , .jpg, .png',
    [UploadFileExtensions.PDF]: '.pdf, application/pdf',
    [UploadFileExtensions.VIDEO]: 'video/mp4, .mp4',
    [UploadFileExtensions.CSV_DOCUMENT]: '.csv, text/csv , application/csv',
    [UploadFileExtensions.EXCEL_DOCUMENT]:
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel', // .xls, .xlsx",
    [UploadFileExtensions.MICROSOFT_DOCUMENT]:
        '.odt, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', // anything that smells like a microsoft word document,
    [UploadFileExtensions.POWERPOINT]:
        '.ppt, .pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

export const getFileExtensionFromMap = (extension: UploadFileExtensions) => FILE_EXTENSION_MAP[extension];

export const FILE_UPLOAD_EXTENSIONS = [
    { label: 'ALL', value: UploadFileExtensions.ALL, description: 'no restriction' },
    { label: 'Image', value: UploadFileExtensions.IMAGE, description: '.png,.jpeg' },
    { label: 'Video', value: UploadFileExtensions.VIDEO, description: '.mp4' },
    { label: 'PDF', value: UploadFileExtensions.PDF, description: '.pdf' },
    { label: 'CSV', value: UploadFileExtensions.CSV_DOCUMENT, description: '.csv' },
    { label: 'Excel', value: UploadFileExtensions.EXCEL_DOCUMENT, description: '.xls, .xlsx' },
    { label: 'Doc', value: UploadFileExtensions.MICROSOFT_DOCUMENT, description: '.doc,.docx' },
    { label: 'Powerpoint', value: UploadFileExtensions.POWERPOINT, description: '.ppt, .pptx' },
];

export interface AllowedInputType {
    value: InputType;
    label: string;
}

export const FILE_UPLOAD_INPUT: AllowedInputType = { value: 'file', label: 'File Upload' };
export const DATE_INPUT_TYPE: AllowedInputType = { value: 'date', label: 'Date' };
export const DEFAULT_SHORT_ANSWER_INPUT_TYPES: AllowedInputType[] = [
    { value: 'wysiwyg', label: 'Text Editor' },
    { value: 'number', label: 'Number' },
    { value: 'text', label: 'Plain Text' },
];

export interface CustomInputFormField extends BaseExtraField {
    type: InputType;
    allowedTypes?: AllowedInputType[];
    extension?: UploadFileExtensions; // allowed file upload extensions
    optionLabels?: string[];
}

export interface SelectOption {
    value: string | number;
    label: string;
}

export interface CustomMultiSelectFormField extends BaseExtraField {
    type: 'radio' | 'checkbox';
    optionLabels: string[];
    useOptions: boolean;
    options: SelectOption[];
    canFilter: boolean;
}

export interface CustomLikertScaleFormField extends BaseExtraField {
    type: 'scale';
    optionLabels: {
        value: number;
        label: string;
    }[];
}

export const DEFAULT_RECRUITMENT_INSTRUCTIONS_CONFIG: GeneralConfig = {
    instructions:
        "Please fill out the following form if you'd like to volunteer as a judge and evaluate presentations " +
        'for this event.',
};

export const DEFAULT_GENERAL_CONFIG: GeneralConfig = {
    instructions:
        'Please fill out the following form with your poster or presentation information. ' +
        'Please only submit one form per presentation, and please note that this submission is final.',
};

export const DEFAULT_GENERALIZED_CONFIG = {
    general: DEFAULT_RECRUITMENT_INSTRUCTIONS_CONFIG,
    extraFields: [],
};

export const DEFAULT_PRESENTER_FIELDS_CONFIG: PresenterFieldsConfig = {
    presenterTitle: 'Presenter',
    maxAddlPresenters: 7,
    firstNameDescription: '',
    lastNameDescription: '',
    emailDescription: '',
    level: {
        isDisplayed: true,
        options: [],
        useOptions: false,
        isRequired: true,
        canChangeLabel: true,
        isPermanent: false,
        sharing: null,
        isShownInSummary: false,
        hash: null,
        label: 'Level/Classification',
        description: '',
        type: 'radio',
        optionLabels: [
            'Freshman',
            'Sophomore',
            'Junior',
            'Senior',
            "Associate's Degree Student",
            'Graduate Student',
            'Postdoctoral Fellow',
            'Faculty',
        ],
        canFilter: false,
        stage: 1,
    },
    major: {
        isDisplayed: true,
        canChangeLabel: true,
        isRequired: true,
        isShownInSummary: false,
        isPermanent: false,
        hash: null,
        label: 'Major/Department',
        description: '',
        type: 'text',
        stage: 1,
    },
    extraFields: [],
};

export const DEFAULT_PRESENTATION_FIELDS_CONFIG: PresentationFieldsConfig = {
    title: {
        isDisplayed: true,
        isRequired: true,
        canChangeLabel: true,
        isShownInSummary: false,
        hash: null,
        label: 'Title',
        description: '',
        isPermanent: false,
        type: 'wysiwyg',
        stage: 1,
    },
    mentor: {
        isDisplayed: true,
        isRequired: false,
        isShownInSummary: true,
        hash: null,
        label: 'Mentor',
        isPermanent: false,
        description: '',
        optionLabels: null,
        canChangeLabel: false,
        type: 'text',
        stage: 1,
    },
    abstract: {
        isDisplayed: true,
        canChangeLabel: true,
        isRequired: true,
        isPermanent: false,
        isShownInSummary: false,
        hash: null,
        label: 'Abstract or Description',
        description:
            'Please note when pasting your abstract, certain formatting ' +
            '(e.g. bold, italics, etc.) may not be kept. Please double check your abstract ' +
            'and re-add formatting where necessary.',
        type: 'wysiwyg',
        stage: 1,
    },
    subjects: {
        isDisplayed: true,
        isRequired: true,
        isShownInSummary: false,
        useOptions: false,
        sharing: null,
        isPermanent: false,
        canChangeLabel: true,
        options: [],
        hash: null,
        label: 'Subject',
        description: '',
        type: 'checkbox',
        optionLabels: [
            'Arts, Design, and Performing Arts',
            'Biological & Life Sciences',
            'Business & Economics',
            'Chemical Sciences',
            'Computational Sciences',
            'Cultural & Language Studies',
            'Communication & Journalism',
            'Education',
            'Environmental Sciences',
            'Engineering',
            'Humanities',
            'Mathematics & Quantitative Studies',
            'Medical & Health Sciences',
            'Physical Sciences & Astronomy',
            'Social & Behavioral Sciences',
        ],
        canFilter: true,
        stage: 1,
    },
    extraFields: [],
};

export const EXPERTISE_AREA_FIELD_LABEL = 'Expertise Areas';

export const DEFAULT_MEDIA_FIELDS_CONFIG: MediaFieldsConfig = {
    poster: {
        isDisplayed: true,
        videoType: 'youtube',
        label: 'Poster / Slides (PDF + Video)',
        posterUploadDescription:
            'Upload a PDF of your poster. PDF must be no more than 10MB in size.  If your file ' +
            'exceeds this limit, you can compress the file to reduce its size.  We recommend Smallpdf.com or another ' +
            'online tool to compress your file if necessary.',
        isOptionalFieldDisplayed: true,
        voiceoverVideoLinkDescription:
            'Please upload a video (2-5 mins recommended) of you describing your work/poster ' +
            'to YouTube and paste the link below (only YouTube links are supported). Please make the ' +
            'YouTube video settings as UNLISTED. Note: YouTube Shorts videos are not supported.',
    },
    oral: {
        isDisplayed: true,
        label: 'Oral (Video)',
        videoType: 'youtube',
        presentationVideoLinkDescription:
            'Please upload your oral presentation to YouTube and paste the link below (only ' +
            'YouTube links are supported). Please make the YouTube video settings as UNLISTED. Note: YouTube Shorts videos are not supported.',
        isOptionalFieldDisplayed: true,
        slidesUploadDescription:
            '(Optional) Upload a PDF of your slides or other supporting document. ' +
            'PDF must be no more than 10MB in size.  If your file exceeds this limit, you can compress the file to reduce ' +
            'its size.  We recommend Smallpdf.com or another online tool to compress your file if necessary.',
    },
    exhibition: {
        isDisplayed: true,
        videoType: 'youtube',
        label: 'Exhibit, Performance, or Demonstration (Video + Video)',
        presentationVideoLinkDescription:
            'Please upload a video of your exhibit or performance to YouTube and paste the ' +
            'link below (only YouTube links are supported). Please make the YouTube video settings as UNLISTED. Note: YouTube Shorts videos are not supported.',
        isOptionalFieldDisplayed: true,
        voiceoverVideoLinkDescription:
            'Please upload a video (2-5mins recommended) of you describing your ' +
            'exhibit/performance to YouTube and paste the link below (only YouTube links are supported). ' +
            'Please make the YouTube video settings as UNLISTED. Note: YouTube Shorts videos are not supported.',
    },
    stage: 1,
};

export const DEFAULT_CUSTOM_INPUT_FORM_FIELD_CONFIG: CustomInputFormField = {
    isDisplayed: true,
    isRequired: true,
    isShownInSummary: false,
    canChangeLabel: true,
    isPermanent: false,
    label: 'New Short Answer',
    description: '',
    type: 'text',
    stage: 1,
};

export const DEFAULT_CUSTOM_FILE_UPLOAD_FIELD_CONFIG: CustomInputFormField = {
    isDisplayed: true,
    isRequired: true,
    isShownInSummary: false,
    extension: UploadFileExtensions.ALL,
    canChangeLabel: true,
    isPermanent: false,
    label: 'New File Upload',
    description: '',
    type: 'file',
    allowedTypes: [FILE_UPLOAD_INPUT],
    stage: 1,
};

export const DEFAULT_DATE_FIELD_CONFIG: CustomInputFormField = {
    isDisplayed: true,
    isRequired: true,
    isShownInSummary: false,
    canChangeLabel: true,
    isPermanent: false,
    label: 'New Date Field',
    description: '',
    type: 'date',
    allowedTypes: [DATE_INPUT_TYPE],
    stage: 1,
};

export const DEFAULT_FINAL_SURVEY_INPUT_FIELD_CONFIG: CustomInputFormField = {
    isDisplayed: true,
    isRequired: true,
    isShownInSummary: false,
    canChangeLabel: true,
    isPermanent: false,
    label: 'Please share any final feedback you have on the presentations you evaluated.',
    description: '',
    type: 'wysiwyg',
    stage: 1,
};

export const DEFAULT_CUSTOM_MULTI_SELECT_FORM_FIELD_CONFIG: CustomMultiSelectFormField = {
    isDisplayed: true,
    isRequired: true,
    isShownInSummary: false,
    useOptions: false,
    isPermanent: false,
    options: [],
    label: 'New Multi-Select',
    description: '',
    optionLabels: [],
    canChangeLabel: true,
    type: 'radio',
    canFilter: false,
    stage: 1,
};

export const DEFAULT_CUSTOM_LIKERT_SCALE_FORM_FIELD_CONFIG: CustomLikertScaleFormField = {
    isDisplayed: true,
    isRequired: true,
    label: 'New Likert Scale',
    canChangeLabel: true,
    isPermanent: false,
    sharing: null,
    isShownInSummary: true,
    description: '',
    optionLabels: [],
    type: 'scale',
    stage: 1,
};

export const DEFAULT_PRESENTATION_FORM_CONFIG: PresentationFormConfig = {
    general: DEFAULT_GENERAL_CONFIG,
    presenterFields: DEFAULT_PRESENTER_FIELDS_CONFIG,
    presentationFields: DEFAULT_PRESENTATION_FIELDS_CONFIG,
    mediaFields: DEFAULT_MEDIA_FIELDS_CONFIG,
};

export const DEFAULT_FINAL_NOTES_CONFIG = {
    general: { ...DEFAULT_GENERAL_CONFIG },
    extraFields: [DEFAULT_FINAL_SURVEY_INPUT_FIELD_CONFIG],
} as GeneralizedFormConfig;
