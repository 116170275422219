import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { distinctUntilChanged, filter, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DonationConfig, Event, Fund } from '@models';
import { AppService, AuthenticationService, DonationsService, FundsService } from '@services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isEmailValid } from '@functions';
import * as countryList from 'country-list';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-donation',
    templateUrl: './donation.component.html',
    styleUrls: ['./donation.component.scss'],
})
export class DonationComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    readonly minDonationAmount = 1;
    readonly maxDonationAmount = 999999;

    @Output() submitted = new EventEmitter<boolean>();
    @Input() donationConfig: DonationConfig;

    isNative = false;
    loading = false;
    funds: Fund[] = [];
    selectedFund: Fund;
    isSubmitted: boolean;
    donationAmounts: number[] = [5, 10, 20, 50, 100];
    selectedAmountIndex: number;
    creditCardValid = false;
    public form: FormGroup;
    public event: Event;
    public error: string;

    constructor(
        private fb: FormBuilder,
        private appService: AppService,
        private donationsService: DonationsService,
        private authenticationService: AuthenticationService,
        private fundService: FundsService
    ) {
        super();
    }

    ngOnInit() {
        this.isNative = Capacitor.isNativePlatform();
        this.form = this.createForm();

        this.appService
            .getEvent()
            .pipe(filter(e => !!e))
            .pipe(
                tap((event: Event) => {
                    this.event = event;

                    // If event covers transaction fee, then don't show the cover transaction fee field
                    if (this.event.eventFeatureFlags.coverDonationsTransactionFees) {
                        this.form.controls['coverTransactionFee'].setValue(false); // Event covers transaction fee, so donor does not need to cover it
                    }

                    return event;
                })
            )
            .pipe(switchMap(event => this.fundService.getFunds(event.eventId)))
            .pipe(tap(funds => (this.funds = funds.filter(fund => fund.connected && fund.enabled))))
            .pipe(switchMap(() => this.donationsService.currentDonation))
            .pipe(
                filter(e => !!e),
                first()
            )
            .subscribe(donation => this.form.patchValue(donation));

        this.form
            .get('fund')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .pipe(distinctUntilChanged())
            .subscribe(fundId => {
                this.selectedFund = this.funds.find(fund => fund.fundId === fundId);
                if (!this.selectedFund) {
                    return;
                }
                const { card } = this.donationsService.createDonationCard(this.selectedFund);
                card.on('change', ({ error, complete }) => {
                    this.error = error ? error.message : '';
                    this.creditCardValid = complete;
                });
            });

        // save the state of the current donation form in a global behavior subject
        this.form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(currentDonation => this.donationsService.updateCurrentDonation(currentDonation));

        this.authenticationService
            .getCurrentUser()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(user => {
                if (user) {
                    this.form.patchValue({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                    });
                }
            });
    }

    public selectAmount(index: number) {
        this.selectedAmountIndex = index;
        if (index === this.donationAmounts.length + 1) {
            return;
        }
        this.form.get('amount').patchValue(this.donationAmounts[index] || 0);
    }

    get isSubmitDisabled(): boolean {
        return !this.form.valid || !isEmailValid(this.form.get('email').value) || !this.creditCardValid || this.loading;
    }

    public donate() {
        const { value } = this.form;

        //stripe billing details
        const billingDetails = {
            name: `${value.firstName} ${value.lastName}`,
            email: value.email,
            address: {
                city: value.city,
                country: countryList.getCode(value.country),
                state: value.state,
            },
        };
        this.loading = true;
        return this.donationsService
            .createDonation({
                fundId: this.selectedFund.fundId,
                ...this.form.value,
            })
            .pipe(
                switchMap(({ clientSecret }) =>
                    this.donationsService.confirmDonationPayment(clientSecret, billingDetails)
                )
            )
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(() => this.submitted.emit(true));
    }

    private createForm() {
        return this.fb.group({
            fund: [null, Validators.required],
            amount: [
                null,
                [Validators.required, Validators.min(this.minDonationAmount), Validators.max(this.maxDonationAmount)],
            ],
            coverTransactionFee: [null, Validators.required],
            anonymous: [null, Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            address: ['', Validators.required],
            zip: ['', Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            city: ['', Validators.required],
        });
    }
}
